const IconDeleteImg = () =>{
    return <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.43743 4.82146C5.35261 4.82146 6.09368 4.07593 6.09368 3.16521C6.09368 2.2545 5.34814 1.51343 4.43743 1.51343C3.52672 1.51343 2.78564 2.2545 2.78564 3.16521C2.78564 4.07593 3.52672 4.82146 4.43743 4.82146ZM4.43743 2.40182C4.86154 2.40182 5.20082 2.74111 5.20082 3.16075C5.20082 3.58039 4.85707 3.92414 4.43743 3.92414C4.01779 3.92414 3.6785 3.58039 3.6785 3.16075C3.6785 2.74111 4.01779 2.40182 4.43743 2.40182Z" fill="#4A4A4A"/>
    <path d="M7.14286 10.7143H1.61607C1.21875 10.7143 0.892857 10.3884 0.892857 9.99107V9.95536L3.77679 7.10268C4.21429 6.67411 4.90625 6.64732 5.37946 7.01339L6.09375 7.71875L6.14732 7.76339C6.44643 7.97768 6.875 7.96429 7.14732 7.75L9.46429 5.47321C10.0357 4.91071 10.875 4.70982 11.6518 4.95536C11.8973 5.03571 12.1295 5.16518 12.3125 5.3125L12.5045 5.50446V5.80804C12.5045 6.05357 12.7054 6.25446 12.9509 6.25446C13.1964 6.25446 13.3973 6.05357 13.3973 5.80804V1.61607C13.3973 0.723214 12.6741 0 11.7812 0H1.61607C0.723214 0 0 0.723214 0 1.61607V9.99107C0 10.8839 0.723214 11.6071 1.61607 11.6071H7.14286C7.38839 11.6071 7.58929 11.4062 7.58929 11.1607C7.58929 10.9152 7.38839 10.7143 7.14286 10.7143ZM1.61607 0.892857H11.7768C12.1741 0.892857 12.5 1.21875 12.5 1.61607V4.36607C12.317 4.25893 12.1205 4.16518 11.9196 4.10268C10.8259 3.75446 9.64732 4.03571 8.83482 4.83482L6.63393 7L6.41071 6.78125L6.41964 6.77232L6.10268 6.46429C5.29018 5.66964 3.96429 5.66964 3.14732 6.46429L0.892857 8.69643V1.61607C0.892857 1.21875 1.21875 0.892857 1.61607 0.892857Z" fill="#4A4A4A"/>
    <path d="M12.0983 10.826C12.2832 10.826 12.4331 10.9759 12.4331 11.1608V13.0581C12.4331 13.2431 12.2832 13.393 12.0983 13.393C11.9134 13.393 11.7635 13.2431 11.7635 13.0581V11.1608C11.7635 10.9759 11.9134 10.826 12.0983 10.826Z" fill="#4A4A4A"/>
    <path d="M11.0938 11.1608C11.0938 10.9759 10.9439 10.826 10.759 10.826C10.5741 10.826 10.4242 10.9759 10.4242 11.1608V13.0581C10.4242 13.2431 10.5741 13.393 10.759 13.393C10.9439 13.393 11.0938 13.2431 11.0938 13.0581V11.1608Z" fill="#4A4A4A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2009 9.48671C10.2009 8.80868 10.7506 8.25903 11.4286 8.25903C12.1066 8.25903 12.6563 8.80868 12.6563 9.48671H13.9956C14.1805 9.48671 14.3304 9.63662 14.3304 9.82153C14.3304 10.0065 14.1805 10.1564 13.9956 10.1564H13.6607L13.6607 12.5894C13.6607 13.3395 13.6607 13.7145 13.5147 14.001C13.3863 14.253 13.1814 14.4579 12.9294 14.5863C12.6429 14.7322 12.2679 14.7322 11.5178 14.7322H11.3393C10.5893 14.7322 10.2142 14.7322 9.92772 14.5863C9.67571 14.4579 9.47082 14.253 9.34242 14.001C9.19645 13.7145 9.19646 13.3394 9.19647 12.5893L9.19652 10.1564H8.86168C8.67676 10.1564 8.52686 10.0065 8.52686 9.82153C8.52686 9.63662 8.67676 9.48671 8.86168 9.48671H10.2009ZM10.8706 9.48671C10.8706 9.17852 11.1204 8.92868 11.4286 8.92868C11.7368 8.92868 11.9866 9.17852 11.9866 9.48671H10.8706ZM9.86617 10.1564H12.9911L12.991 12.5894C12.991 12.9755 12.9905 13.2248 12.975 13.4145C12.9601 13.5964 12.9349 13.6639 12.918 13.697C12.8538 13.823 12.7514 13.9254 12.6254 13.9896C12.5923 14.0065 12.5248 14.0317 12.3429 14.0466C12.1532 14.0621 11.9039 14.0626 11.5178 14.0626H11.3393C10.9533 14.0626 10.704 14.0621 10.5142 14.0466C10.3323 14.0317 10.2648 14.0065 10.2317 13.9896C10.1057 13.9254 10.0033 13.823 9.93909 13.697C9.92222 13.6639 9.89699 13.5964 9.88213 13.4145C9.86663 13.2247 9.86611 12.9755 9.86612 12.5894L9.86617 10.1564Z" fill="#4A4A4A"/>
    </svg>
}

export default IconDeleteImg
