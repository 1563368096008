import { memo } from 'react';
import { thumbnailImageFlexible } from '../../../../helpers/commons';

const MediaImage = memo(({ url, alt }) => {
    const imageUrl = thumbnailImageFlexible(url, null);

    return <img
        src={imageUrl}
        width="auto"
        alt={alt}
        loading="lazy"
    />
});

export default MediaImage;
