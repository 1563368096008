import { Box, InlineStack, Text } from "@shopify/polaris";

const StarRating = ({
    avgRating,
    background,
    itemKey,
    color,
    starSize,
    padding,
}) => {
    return (
        <Box
            padding={padding ?? 150}
            borderRadius={150}
            background={background || "bg-fill"}
        >
            <InlineStack
                gap={150}
                blockAlign="center"
                wrap={false}
                align="start"
            >
                <InlineStack
                    blockAlign="center"
                    wrap={false}
                    align="start"
                    gap={"100"}
                >
                    {[...Array(5)].map((_, index) => (
                        <svg
                            width={starSize || 16}
                            height={starSize || 16}
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            key={`${itemKey}-star-${index}`}
                        >
                            <path
                                d="M9.71938 5.89299L9.83364 6.19117L10.1522 6.21292L14.7699 6.5283L11.209 9.66486L10.9817 9.86504L11.0539 10.1592L12.2126 14.8841L8.27125 12.2961L7.99681 12.1159L7.72237 12.2961L3.78105 14.8841L4.9398 10.1592L5.01187 9.86527L4.78494 9.6651L1.22878 6.5283L5.84145 6.21292L6.16 6.19114L6.27424 5.89299L7.99681 1.39742L9.71938 5.89299Z"
                                fill={
                                    index + 1 <= avgRating
                                        ? color || "#303030"
                                        : "#FFFFFF"
                                }
                                stroke={color || "#303030"}
                            />
                        </svg>
                    ))}
                </InlineStack>
                <p
                    className="Polaris-Text--root Polaris-Text--headingSm"
                    style={{
                        lineHeight: "100%",
                    }}
                >
                    {Number(avgRating).toFixed(1)}
                </p>
            </InlineStack>
        </Box>
    );
};
export default StarRating;
