import Http from "../../../../layouts/Http";
import * as Config from "../../../../api/Config";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
const useCallbackAllMedia = () => {
  const [isLoading, setIsLoading] = useState({
    all_media: false,
    review_detail: false,
  });

  const [isLoadingDeleteMedia, setIsLoadingDeleteMedia] = useState(false);
  const [isLoadingDeleteReview, setIsLoadingDeleteReview] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [review, setReview] = useState(null);

  const getReviewDetail = (review_id) => {
    setIsLoading((prev) => ({ ...prev, review_detail: true }));
    Http.get(`${Config.API_URL}/reviews/${review_id}`)
      .then((response) => {
        if (response.data.data) {
          setReview((prev) => {
            return { ...prev, ...response.data.data };
          });
        }
      })
      .catch((e) => {
        window.shopify.toast.show("Get review detail failed", {
          duration: 3000,
        });
      })
      .finally(() => {
        setIsLoading((prev) => ({ ...prev, review_detail: false }));
      });
  };

  const deleteMedias = (media_ids) => {
    setIsLoadingDeleteMedia(true);
    Http.post(`${Config.API_URL}/reviews/delete-medias`, {
      media_ids,
    })
      .then((response) => {
        searchParams.set("dms", media_ids.join(","));
        setSearchParams(searchParams);
      })
      .catch((e) => {
        window.shopify.toast.show("Delete media failed", {
          duration: 3000,
        });
      })
      .finally(() => {
        setIsLoadingDeleteMedia(false);
      });
  };

  const deleteReview = (review_id) => {
    setIsLoadingDeleteReview(true);

    Http.put(`${Config.API_URL}/reviews/delete`, {
      review_ids: review_id,
    })
      .then((response) => {
        searchParams.set("drs", review_id);
        setSearchParams(searchParams);
      })
      .catch((e) => {
        window.shopify.toast.show("Delete review failed", {
          duration: 3000,
        });
      })
      .finally(() => {
        setIsLoadingDeleteReview(false);
        setReview(null);
      });
  };

  useEffect(() => {
    if (searchParams.get("review_id")) {
      getReviewDetail(searchParams.get("review_id"));
    }
  }, [searchParams]);

  return {
    isLoading,
    isLoadingDeleteMedia,
    isLoadingDeleteReview,
    getReviewDetail,
    review,
    deleteMedias,
    deleteReview,
  };
};

export default useCallbackAllMedia;
