import { Box, InlineStack, Thumbnail, Text, Icon } from "@shopify/polaris";
import { ExternalIcon } from "@shopify/polaris-icons";
const ProductInfoBox = ({ review }) => {
    return (
        <Box padding={150} borderRadius="150" background="bg-fill-secondary">
            <InlineStack
                gap={150}
                blockAlign="center"
                align="space-between"
                wrap={false}
            >
                <Box width="90%">
                    <InlineStack
                        gap={150}
                        blockAlign="center"
                        align="start"
                        wrap={false}
                    >
                        <Thumbnail
                            source={review.product_featured_image}
                            size="extraSmall"
                        />

                        <Text truncate>{review.product_title}</Text>
                    </InlineStack>
                </Box>
                <Box>
                    <Icon source={ExternalIcon} />
                </Box>
            </InlineStack>
        </Box>
    );
};
export default ProductInfoBox;
