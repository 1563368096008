import { memo, useCallback } from "react";
import MediaContent from "./components/MediaContent";
import MediaOverlay from "./components/MediaOverlay";
import { useSearchParams } from "react-router-dom";

const MediaItem = memo(
    ({
        t,
        mediaItem,
        selectedReview,
        setSelectedReview,
        mediaIndex,
        listSelected,
        setListSelected,
        onDeleteMediaReview,
    }) => {
        const isSelected = listSelected.some(
            (item) => item.id === mediaItem.id
        );
        const isCurrentReview =
            Number(mediaItem.review_id) === Number(selectedReview);

        const [searchParams, setSearchParams] = useSearchParams();

        const setSearchParam = useCallback(
            (key, value) => {
                searchParams.set(key, value);
                setSearchParams(searchParams);
            },
            [searchParams, setSearchParams]
        );

        const handleSelectMedia = useCallback(
            (value) => {
                setListSelected((prev) =>
                    value
                        ? [...prev, mediaItem]
                        : prev.filter((item) => item.id !== mediaItem.id)
                );
            },
            [mediaItem, setListSelected]
        );

        const handleSelectedMedia = useCallback(() => {
            if (!isCurrentReview) {
                const reviewId = Number(mediaItem.review_id);
                setSelectedReview(reviewId);
                setSearchParam("review_id", reviewId);
            }
        }, [isCurrentReview, mediaItem.review_id, setSelectedReview]);

        const handleDeleteClick = useCallback(
            (e) => {
                e.stopPropagation();
                onDeleteMediaReview(mediaItem.id);
            },
            [mediaItem.id, onDeleteMediaReview]
        );

        return (
            <div
                className={`all-media_media--item ${
                    isCurrentReview ? "review-selected" : ""
                } ${isSelected ? "selected-media--item" : ""}`}
                onClick={handleSelectedMedia}
            >
                <MediaContent
                    mediaType={mediaItem.media_type}
                    url={mediaItem.url}
                    videoThumb={mediaItem.video_thumb}
                    title={mediaItem.name}
                    alt={mediaItem.name}
                />

                <MediaOverlay
                    t={t}
                    isSelected={isSelected}
                    onSelect={handleSelectMedia}
                    onDelete={handleDeleteClick}
                />

                {isCurrentReview && (
                    <label className="all-media_media-item--index">
                        {mediaIndex}
                    </label>
                )}
            </div>
        );
    }
);

export default MediaItem;
