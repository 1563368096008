import {
    BlockStack,
    Box,
    Button,
    Popover,
    RadioButton,
    Text,
} from "@shopify/polaris";
import { SortIcon } from "@shopify/polaris-icons";
import { useState } from "react";
const sortOptions = [
    {
        label: "Most recent",
        value: "most_recent",
    },
    {
        label: "Rating (high to low)",
        value: "review_star",
    },
    {
        label: "Product (A - Z)",
        value: "product_title_asc",
    },
    {
        label: "Product (Z - A)",
        value: "product_title_desc",
    },
];
const SingleSort = ({ checked, setChecked }) => {
    const [active, setActive] = useState(false);
    const activator = <Button onClick={() => setActive(!active)} icon={SortIcon} />;
    return (
        <Popover
            activator={activator}
            active={active}
            onClose={() => setActive(false)}
        >
            <Box padding={400}>
            <BlockStack gap={100}>
                <Text>Sort by</Text>
                {sortOptions.map((item, index) => (
                    <RadioButton
                        key={item.value}
                        checked={checked === item.value}
                        onChange={() => setChecked(item.value)}
                        label={item.label}
                    />
                ))}
            </BlockStack>
            </Box>
        </Popover>
    );
};

export default SingleSort;
