import { memo } from 'react';
import MediaVideo from './MediaVideo';
import MediaImage from './MediaImage';

const MediaContent = memo(({ mediaType, ...props }) => (
    mediaType === "video" 
        ? <MediaVideo {...props} />
        : <MediaImage {...props} />
));

export default MediaContent;
