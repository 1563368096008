export const API_TOKEN = process.env.REACT_APP_API_KEY;
export const API_URL = process.env.REACT_APP_API_URL;
export const URL = process.env.REACT_APP_URL;
export const APP_NAME = process.env.REACT_APP_NAME;
export const IMAGE_URL =
    process.env.REACT_APP_MEDIA_URL ??
    "https://ts-media.syd1.cdn.digitaloceanspaces.com/";
export const THEME_APP_EXTENSION_ID = process.env.REACT_APP_EXTENSION_ID;
export const IMPORT_REVIEW_URL = process.env.REACT_APP_IMPORT_REVIEW_URL;
export const QR_CODE_URL = process.env.REACT_APP_QR_CODE_URL;
export const QR_CODE_SCAN = process.env.REACT_APP_QR_CODE_SCAN;
export const QR_LOGO_URL = process.env.REACT_APP_QR_LOGO_URL;
