import { memo } from 'react';

const MediaVideo = memo(({ videoThumb, url, title }) => (
    videoThumb ? (
        <img src={videoThumb} width="100%" alt="" loading="lazy" />
    ) : (
        <iframe
            src={url}
            title={title}
            id="iframe-video"
            allowFullScreen={false}
            loading="lazy"
        />
    )
));

export default MediaVideo;
