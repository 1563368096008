import { memo } from 'react';
import { Box, Button, Checkbox, InlineStack } from "@shopify/polaris";
import { DeleteIcon } from "@shopify/polaris-icons";

const MediaOverlayActions = memo(({ 
    isSelected, 
    onSelect, 
    onDelete 
}) => (
    <div className="all-media_media-item--action">
        <InlineStack
            blockAlign="center"
            align="space-between"
            gap={200}
            wrap={false}
        >
            <div
                className="all-media_media-item--check-box"
                onClick={(e) => e.stopPropagation()}
            >
                <Box width="16px">
                    <Checkbox
                        labelHidden
                        checked={isSelected}
                        onChange={onSelect}
                    />
                </Box>
            </div>
            <div onClick={(e) => e.stopPropagation()}>
                <Button
                    onClick={onDelete}
                    icon={DeleteIcon}
                    tone={isSelected ? "critical" : undefined}
                />
            </div>
        </InlineStack>
    </div>
));

export default MediaOverlayActions;
