import { useEffect } from "react";

const useDynamicTitle = (title) => {
    useEffect(() => {
        document.title = `${title || "Trustshop"} - Build trust in the store`;
    }, [title]);

    return null;
};

export default useDynamicTitle;
