import {
    BlockStack,
    Box,
    Button,
    ButtonGroup,
    InlineGrid,
    Page,
    Text,
} from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import emptyState from "../../../assets/images/review-empty-state.svg";
import MediaList from "./MediaList";
import MediaReviewDetail from "./MediaReviewDetail";
import { useEffect, useState } from "react";
import useEffectAllMedia from "./hooks/useEffectAllMedia";
import useCallbackAllMedia from "./hooks/useCallbackAllMedia";
import { useTranslation } from "react-i18next";
import useDynamicTitle from "../../../layouts/MainLayout/useDynamicTitle";

const AllMedia = () => {
    useDynamicTitle("All media");
    const { t } = useTranslation("review-management");
    const navigate = useNavigate();
    const { isLoadingAllMedia, allMedia, cursorMedia, statisticsMedias } = useEffectAllMedia();
    const { isLoading, review, deleteMedias, deleteReview } = useCallbackAllMedia();
    const [currentImg, setCurrentImg] = useState(0);

    useEffect(()=>{
        setCurrentImg(0)
    },[review])

    const handleBackActions = () => {
        navigate("/review-management");
    };

    if (isLoadingAllMedia) {
        return <div>Loading...</div>;
    }

    return (
        <div
            style={{
                maxWidth: "1000px",
                margin: "auto",
            }}
            className="all-media-page--container"
        >
            <Page
                title={t("all_media_page.title", { ns: "review-management" })}
                fullWidth
                backAction={{
                    content: "Back",
                    onAction: handleBackActions,
                }}
            >
                
                    <InlineGrid
                        columns={{
                            sm: "2fr 1fr",
                        }}
                        gap={400}
                    >
                        <MediaList
                            medias={allMedia}
                            setCurrentImg={setCurrentImg}
                            review={review}
                            isLoading={isLoading}
                            cursorMedia={cursorMedia}
                            statisticsMedias={statisticsMedias}
                            onDeleteMedia={deleteMedias}
                        />
                        <MediaReviewDetail
                            t={t}
                            currentImg={currentImg}
                            setCurrentImg={setCurrentImg}
                            review={review}
                            isLoading={isLoading}
                            onDeleteReview={deleteReview}
                            onDeleteMedia={deleteMedias}
                        />
                    </InlineGrid>
                
            </Page>
        </div>
    );
};

export default AllMedia;
