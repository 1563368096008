import { memo } from "react";
import MediaOverlayActions from "./MediaOverlayActions";
import MediaOverlayZoom from "./MediaOverlayZoom";

const MediaOverlay = memo(({ t, isSelected, onSelect, onDelete }) => (
    <div className="all-media_media-item--overlay">
        <MediaOverlayActions
            isSelected={isSelected}
            onSelect={onSelect}
            onDelete={onDelete}
        />
        <MediaOverlayZoom t={t} />
    </div>
));

export default MediaOverlay;
