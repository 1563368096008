/**
 * Generates a flexible thumbnail URL for images hosted on imagedelivery.net
 * @param {string} url - The original image URL
 * @param {number|string} [size=150] - The desired thumbnail size
 * @returns {string} The modified URL with the specified size or 'public'
 */
export const thumbnailImageFlexible = (url, size = 150) => {
  const IMAGE_URL_REGEX = /^https:\/\/imagedelivery\.net[/A-Za-z0-9_\-?/]*/;

  if (!IMAGE_URL_REGEX.test(url)) {
    return url;
  }

  const hasPublicSuffix = url.endsWith("/public");
  const baseUrl = hasPublicSuffix ? url.slice(0, -7) : url;
  const separator = baseUrl.endsWith("/") ? "" : "/";
  
  return `${baseUrl}${separator}${size || "public"}`;
};
