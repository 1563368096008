import { memo } from "react";
import { Icon, InlineStack, Text } from "@shopify/polaris";
import { MaximizeIcon } from "@shopify/polaris-icons";

const MediaOverlayZoom = memo(({ t }) => (
    <div className="all-media_media-item-zoom--overlay">
        <InlineStack>
            <Icon source={MaximizeIcon} />
            <Text>
                {t("all_media_page.review_detail", { ns: "review-management" })}
            </Text>
        </InlineStack>
    </div>
));

export default MediaOverlayZoom;
