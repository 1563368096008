import { BlockStack, Spinner, Text } from "@shopify/polaris";
import noReviewSelected from "../../../assets/images/all-media-no-review-selected.svg";
import CustomerInfoCard from "./../ReviewDetail/CustomerInfoCard";
import ReviewDetail from "./ReviewDetail";
const MediaReviewDetail = ({
    t,
    currentImg,
    setCurrentImg,
    review,
    isLoading,
    onDeleteReview,
    onDeleteMedia
}) => {
    return (
        <div className="all-media-page__secondary-section">
            <div>
                {!isLoading.review_detail ? (
                    review ? (
                        <BlockStack gap={400}>
                            <ReviewDetail
                                t={t}
                                review={review}
                                currentImg={currentImg}
                                setCurrentImg={setCurrentImg}
                                onDeleteReview={onDeleteReview}
                                onDeleteMedia={onDeleteMedia}
                            />
                            <CustomerInfoCard review={review} t={t} />
                        </BlockStack>
                    ) : (
                        <div
                            style={{
                                height: "60vh",
                                maxHeight: "550px",
                                display: "grid",
                                placeContent: "center",
                                placeItems: "center",
                                backgroundColor: "#FFFFFF",
                                borderRadius: "8px",
                                gap: "8px",
                            }}
                        >
                            <img src={noReviewSelected} alt="" />
                            <Text>
                                {t("all_media_page.empty_media_detail", {
                                    ns: "review-management",
                                })}
                            </Text>
                        </div>
                    )
                ) : (
                    <div
                        style={{
                            height: "60vh",
                            maxHeight: "550px",
                            display: "grid",
                            placeContent: "center",
                            placeItems: "center",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "8px",
                            gap: "8px",
                        }}
                    >
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MediaReviewDetail;
