import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  InlineStack,
  Text,
} from "@shopify/polaris";
import IconDeleteImg from "./../../../assets/images/review-management/icon-delete-img";
import { DeleteIcon } from "@shopify/polaris-icons";
import StarRating from "./../../../components/ReviewManagement/StarRating";
import ProductInfoBox from "../../../components/ReviewManagement/ProductInfoBox";
import { useCallback, useState } from "react";
import CustomModalMessage from "../../../components/CustomModalMessage";
import { thumbnailImageFlexible } from "../../../helpers/commons";
const ReviewDetail = ({
  t,
  review,
  currentImg,
  setCurrentImg,
  onDeleteReview,
  onDeleteMedia
}) => {
  const [showModal, setShowModal] = useState(null);

  const handleCancel = () => {
    setShowModal(null);
  };

  const handleDelete = () => {
    showModal === "delete_review"
      ? onDeleteReview(review.id)
      : onDeleteMedia([review.medias[currentImg].id]);

    setShowModal(null);
  };

  const handleSwiperImg = (action) => {
    if (action === "prev") {
      setCurrentImg((prev) =>
        prev === 0 ? review.medias.length - 1 : prev - 1
      );
    } else {
      setCurrentImg((prev) =>
        prev + 1 >= review.medias.length ? 0 : prev + 1
      );
    }
  };

  return (
    <div className="all-media-page__review-detail--container">
      <Card roundedAbove="sm">
        <BlockStack gap={200}>
          <div className="review-detail-img--container">
            <div className="review-detail-img--wrap">
              {review.medias[currentImg]?.media_type === "video" ? (
                <video
                  src={thumbnailImageFlexible(review.medias[currentImg]?.url, null)}
                  title={thumbnailImageFlexible(review.medias[currentImg]?.name, null)}
                  id={`iframe-video`}
                  allowFullScreen={true}
                  loading="lazy"
                  controls
                  width={"100%"}
                  height={"100%"}
                ></video>
              ) : (
                <img
                  src={thumbnailImageFlexible(review.medias[currentImg]?.url, 500)}
                  alt=""
                  style={{
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
            <button
              className="review-detail-swiper--btn left--btn"
              onClick={() => {
                handleSwiperImg("prev");
              }}
            >
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.69888 12.3979L1.30102 7.0001L6.69887 1.60225"
                  stroke="#4A4A4A"
                  strokeWidth="1.79928"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="review-detail-swiper--btn__bg"></span>
            </button>
            <button
              className="review-detail-swiper--btn right--btn"
              onClick={() => {
                handleSwiperImg("next");
              }}
            >
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.30112 1.60205L6.69897 6.9999L1.30112 12.3977"
                  stroke="#4A4A4A"
                  strokeWidth="1.79928"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="review-detail-swiper--btn__bg"></span>
            </button>

            <div className="review-detail--action">
              <InlineStack gap={150} blockAlign="center" align="center">
                <Button icon={IconDeleteImg} onClick={() => setShowModal('delete_media')}>Delete Image</Button>
                <Button icon={DeleteIcon} onClick={() => setShowModal('delete_review')}>Delete Review</Button>
              </InlineStack>
            </div>
          </div>
          <div
            style={{
              overflowX: "auto",
              scrollbarWidth: "thin",
            }}
            className="review-detail-media-list--container"
          >
            <Box width="fit-content">
              <InlineStack
                wrap={false}
                gap={200}
                blockAlign="center"
                align="center"
              >
                {review.medias.map((item, index) => (
                  <div
                    className={`review-detail-media-list--item ${
                      currentImg === index ? "select" : ""
                    }`}
                    key={item.id}
                    onClick={() => setCurrentImg(index)}
                  >
                    <img
                      src={
                        item.media_type === "video"
                          ? item.video_thumb
                          : thumbnailImageFlexible(item.url, 100)
                      }
                      alt=""
                      width={"100%"}
                    />
                  </div>
                ))}
              </InlineStack>
            </Box>
          </div>
        </BlockStack>
        <Box paddingBlockStart={400}>
          <BlockStack gap={200}>
            <InlineStack
              gap={300}
              blockAlign="stretch"
              align="start"
              wrap={false}
            >
              <StarRating
                avgRating={review.review_star}
                background={"bg-surface-active"}
                key={`all-review__review-detail`}
              />
              <Badge
                tone={
                  review.status === "approve"
                    ? "success"
                    : review.status === "reject"
                    ? "critical"
                    : "attention"
                }
              >
                {t(`review_detail.${review.status}`, {
                  ns: "review-management",
                })}
              </Badge>
            </InlineStack>
            <BlockStack gap={0}>
              <Text variant="bodyLg" fontWeight="semibold">
                {review.title}
              </Text>
              <Text>{review.content}</Text>
            </BlockStack>
            <ProductInfoBox review={review} />
          </BlockStack>
        </Box>
      </Card>

      {showModal !== null && (
        <CustomModalMessage
          closeModalAction={handleCancel}
          t={t}
          primaryButton={{
            content: t(showModal === "delete_media" ? "delete_image_btn" : "delete_review_btn", { ns: "translation" }),
            action: handleDelete,
            tone: showModal === "delete_review" ? "critical" : undefined,
          }}
          secondaryButton={{
            content: t("cancel", { ns: "translation" }),
            action: handleCancel,
          }}
          title={t(showModal === "delete_media" ? "delete_image_label" : "delete_review_label", { ns: "translation" })}
          content={t(showModal === "delete_media" ? "delete_image_content" : "delete_review_content", { ns: "translation" })}
        />
      )}
    </div>
  );
};

export default ReviewDetail;
